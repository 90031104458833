import {
    Heading,
    Text,
    Button,
    SimpleGrid,
    Container,
    Flex,
    Center,
    Stack,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import useLocale from '../lib/useLocale';

export default function NotFoundPage() {
    const { format } = useLocale();

    return (
        <Container maxW="container.lg">
            <SimpleGrid
                minChildWidth={{
                    base: '100%',
                    sm: '20em',
                }}
                py={10}
            >
                <Flex direction="column" justify="center">
                    <Heading mb={2}>{format('404error')}</Heading>
                    <Heading as="h1" size="2xl" mb={4}>
                        {format('pageNotFound')}
                    </Heading>
                    <Text color="gray.600" mb={6} whiteSpace="pre-line">
                        {format('sorryWeCouldntFindThePage')}
                    </Text>
                    <Stack direction="row" spacing={4}>
                        <Button
                            as={GatsbyLink}
                            to="/"
                            colorScheme="primary"
                            variant="solid"
                        >
                            {format('goBackHome')}
                        </Button>
                        {/* <Button
                            as={Link}
                            href="https://www.instagram.com/lord_joey_therapy_pug/"
                            colorScheme="primary"
                            variant="outline"
                            isExternal
                        >
                            {format('followJoey')}
                        </Button> */}
                    </Stack>
                </Flex>
                <Center
                    py={{
                        base: 4,
                        sm: 0,
                    }}
                >
                    <StaticImage
                        src="../images/joey1.png"
                        alt="Joey the pug"
                        placeholder="blurred"
                        objectFit="contain"
                        height={500}
                    />
                </Center>
            </SimpleGrid>
        </Container>
    );
}

export const query = graphql`
    query NotFoundPageQuery($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
